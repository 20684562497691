h2.about-banner-head {
  font-size: 6vw;
  filter: brightness(100%);
}

h6.about-banner-head {
  font-size: 4vw;
  filter: brightness(100%);

  font-family: "Dancing Script";
}

.head-strip {
  background: rgb(51, 122, 183);
  color: white;
  font-size: 18px;
  padding: 0.5%;
  margin-bottom: 10px;
  padding-left: 2%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.form-container {
  box-sizing: border-box;
  margin: 15px;
  padding: 30px;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
}

.inner-container {
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
}

.errorMsg {
  font-size: 13px;
  color: red;
  margin-bottom: 14px;
}

.rdbtn {
  margin: 5px;
}

.rdbtnlabel {
  margin-right: 14px;
}

.yearcss {
  size: 300%;
}

/*Image Preview*/

.container-image {
  background-color: #ddd;
  float: right;
  height: 10px;
}

.img-holder {
  display: flex;
  flex-flow: column nowrap;
  min-height: auto;
  justify-content: space-evenly;
  align-items: flex-end;
}

.img-holder img {
  width: 10vw;
  height: 10vw;
  border-radius: 5%;
  border: 1px solid slategray;
  cursor: pointer;
}

.img-holder button {
  margin: 0.5em 0;
  width: 10vw;
  height: 10vw;
  background: #6c757d;
}

#imageinput[type="file"] {
  display: none;
  size: 5px;
}

.label {
  width: 100%;
}

.quali-sec {
  margin-bottom: 20px;
}

.quali-row {
  margin-top: 1%;
}

.image-upload {
  width: 230px;
  height: 30px;
  background-color: black;
  color: white;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.profile_img {
  height: 80%;
  width: 50%;
  float: right;
}

.form-heading {
  font-size: 36px;
}

.heading {
  /* position: relative; */
}

@media screen and (max-width: 768px) {
  .profile_img {
    height: 80%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .form-heading {
    padding: 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    margin-left: 5%;
    margin-top: 2%;
    margin-right: 2%;
  }

  .img-holder {
    display: flex;
    flex-flow: column wrap;
    min-height: auto;
    justify-content: space-evenly;
    align-items: center;
  }

  .img-holder img {
    width: 50%;
    height: auto;
    border-radius: 5%;
    border: 1px solid slategray;
  }

  .img-holder button {
    margin: 0.5em 0;
    width: 30vw;
    height: 30vw;
  }

  /* .form-container {
    box-sizing: border-box;
    margin: 15px;
    padding: 20px;
    padding-left: 0.5%;
    padding-right: 0.5%;
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border: 0px solid rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
  } */
}

.checkbox {
  margin-bottom: 10px;
}
