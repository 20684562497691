.main-container {
  margin-top: 2%;
  margin-bottom: 2%;
}

.search-box {
  width: 100%;
}

.searchbox-container {
  margin-top: -1.5%;
}

.positions-text {
}

.positions {
  font-size: 20px;
  color: #9ad252;
  text-decoration: underline;
}

.positions-text:hover {
  cursor: pointer;
  color: rgb(106, 106, 235);
}

.bottom-margin {
  margin-bottom: 2%;
}

.quali-row > .col-md-1,
.col-md-2 {
  padding-right: 5px;
  padding-left: 5px;
}

.departments-container {
  background-color: #d6d9e4;
  padding: 2%;
}

.departments-container-one {
  background-color: #ffffff;
  padding: 2%;
}

.departments-cards {
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  height: 400px;
}

.departments-cards:hover {
  transform: scale(1.02);
}

.skew-container {
  transform: skewy(-11deg);
}

.blocks {
  display: flex;
  margin-top: 0;
  margin-bottom: 1%;
}

.block {
  min-height: 350px;
  width: 50%;
  width: calc(50% + 2rem);
}

.block--left {
  padding-right: 1%;
  clip-path: polygon(0 0, 100% 0, calc(100% - 3rem) 100%, 0% 100%);
}

.block--right {
  background: url(../../images/office.jpg);
  margin-left: -3rem;
  clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0% 100%);
}

.p-blocks {
  display: flex;
  margin-top: 0;
  margin-bottom: 1%;
}

.p-block {
}

.getInTouchBg {
  background: url(../../images/process-getintouch.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.callBg {
  background: url(../../images/process_call.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.assignmentBg {
  background: url(../../images/process_assignment.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.reviewBg {
  background: url(../../images/process_review.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.techInterviewBg {
  background: url(../../images/process_tech.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.raiserBg {
  background: url(../../images/process_rasier.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.p-block--left {
  width: 70%;
  padding: 2%;
  padding-right: 5%;
  clip-path: polygon(0 0, 100% 0, calc(100% - 3rem) 100%, 0% 100%);
}

.p-block--right {
  width: 40%;

  margin-left: -3rem;
  clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0% 100%);
}

.l-blocks {
  display: flex;
  margin-top: 0;
  margin-bottom: 1%;
  background: #f7f7f7;
}

.l-block {
}

.l-block--left {
  width: 40%;
  /* background: url(../../images/office.jpg); */
  margin-right: -3%;
  margin-left: -3%;
  clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
}

.l-block--right {
  width: 70%;
  padding: 2%;
  padding-left: 5%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 6% 100%);
}

.usp-content {
  padding-top: 15%;
  padding-right: 2%;
  font-size: 20px;
  margin-left: 1%;
  color: #7d7d7d;
  text-align: center;
}

.process-container {
  font-size: 14px;
  margin-top: 2%;
}

.process-container-text {
  color: #7d7d7d;
}

.process-container > .container-fluid > ol > li {
  margin-top: 2%;
}

.career-modal-body {
  min-height: auto;
  height: auto;
  padding: 2%;
}

/* .advantages-container {
} */

.advantages-card {
  color: #000;
  margin-bottom: 1%;
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  text-align: left;
}

.advantages-card-body {
  padding: 0;
  padding-top: 3%;
}

.advantages-col {
  margin-top: 1%;
  margin-bottom: 5%;
}

.quotes-container {
  margin-top: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: #f3f1f1;
}

.main-quote-line-1 {
  font-size: 48px;
  color: #7d7d7d;
}

.main-quote-line-2 {
  font-size: 48px;
  color: #7d7d7d;
  margin-top: -0.5%;
}

.main-quote-line-3 {
  font-size: 48px;
  color: #f5a800;
  margin-top: -0.5%;
}

.second-quote-line-1 {
  margin-top: 4%;
  font-size: 32px;
  color: #e27d00;
  margin-bottom: 4%;
}

.dep-images {
  padding: 5%;
  height: 150px;
}

.process-header {
  font-size: 18px;
  color: #7d7d7d;
  font-weight: bold;
}

label {
  margin-bottom: 0.2rem;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c7c3c3;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c7c3c3;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c7c3c3;
}

@media screen and (max-width: 768px) {
  .process-container {
    display: none;
  }

  .p-block--left {
    width: 100%;
    padding: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .p-block--right {
    display: none;
  }

  .l-block--left {
    display: none;
  }

  .l-block--right {
    width: 100%;
    padding: 0;

    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .l-blocks {
    background: #ffffff;
  }

  .block--right {
    display: none;
  }

  .block--left {
    width: 100%;
  }
}
