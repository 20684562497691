/* .about-banner-img {
  background-image: url("../images/about.png");
  width: 100%;
  height: 400px;
  max-height: 400px;
  background-size: cover;
  padding: 100px;
  filter: brightness(80%);
} */

h2.about-banner-head {
  font-size: 6vw;
  filter: brightness(100%);
}

h6.about-banner-head {
  font-size: 4vw;
  filter: brightness(100%);

  font-family: "Dancing Script";
}

.form-container {
  box-sizing: border-box;
  margin: 15px;
  margin-top: 0;
  padding: 30px;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
}

.errorMsg {
  font-size: 13px;
  color: red;
  margin-bottom: 14px;
}

.rdbtn {
  margin: 5px;
}

.rdbtnlabel {
  margin-right: 14px;
}

.yearcss {
  size: 300%;
}

/*Image Preview*/

.container-image {
  background-color: #ddd;
  float: right;
  height: 10px;
}

.img-holder {
  display: flex;
  flex-flow: column nowrap;
  min-height: auto;
  justify-content: space-evenly;
  align-items: flex-end;
}

.img-holder img {
  width: 30%;
  height: 30%;
  border-radius: 5%;
  border: 1px solid slategray;
}

.img-holder button {
  margin: 0.5em 0;
  width: 10vw;
  height: 10vw;
  background: #6c757d;
}

#imageinput[type="file"] {
  display: none;
  size: 5px;
}

.label {
  width: 100%;
}

.image-upload {
  width: 230px;
  height: 30px;
  background-color: black;
  color: white;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.profile_img {
  height: 80%;
  width: 50%;
  float: right;
}

@media screen and (max-width: 768px) {
  .profile_img {
    height: 80%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  h2.form-heading {
    padding: 0 auto;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .img-holder {
    display: flex;
    flex-flow: column wrap;
    min-height: auto;
    justify-content: space-evenly;
    align-items: center;
  }

  .img-holder img {
    width: 50%;
    height: auto;
    border-radius: 5%;
    border: 1px solid slategray;
  }

  .img-holder button {
    margin: 0.5em 0;
    width: 30vw;
    height: 30vw;
  }
}

.checkbox {
  margin-bottom: 10px;
}
