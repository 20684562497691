@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .about-banner-img {
  background-image: url("../images/about.png");
  width: 100%;
  height: 400px;
  max-height: 400px;
  background-size: cover;
  padding: 100px;
  filter: brightness(80%);
} */

h2.about-banner-head {
  font-size: 6vw;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

h6.about-banner-head {
  font-size: 4vw;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);

  font-family: "Dancing Script";
}

.form-container {
  box-sizing: border-box;
  margin: 15px;
  margin-top: 0;
  padding: 30px;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
}

.errorMsg {
  font-size: 13px;
  color: red;
  margin-bottom: 14px;
}

.rdbtn {
  margin: 5px;
}

.rdbtnlabel {
  margin-right: 14px;
}

.yearcss {
  size: 300%;
}

/*Image Preview*/

.container-image {
  background-color: #ddd;
  float: right;
  height: 10px;
}

.img-holder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  min-height: auto;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.img-holder img {
  width: 30%;
  height: 30%;
  border-radius: 5%;
  border: 1px solid slategray;
}

.img-holder button {
  margin: 0.5em 0;
  width: 10vw;
  height: 10vw;
  background: #6c757d;
}

#imageinput[type="file"] {
  display: none;
  size: 5px;
}

.label {
  width: 100%;
}

.image-upload {
  width: 230px;
  height: 30px;
  background-color: black;
  color: white;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.profile_img {
  height: 80%;
  width: 50%;
  float: right;
}

@media screen and (max-width: 768px) {
  .profile_img {
    height: 80%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  h2.form-heading {
    padding: 0 auto;
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .img-holder {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    min-height: auto;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
  }

  .img-holder img {
    width: 50%;
    height: auto;
    border-radius: 5%;
    border: 1px solid slategray;
  }

  .img-holder button {
    margin: 0.5em 0;
    width: 30vw;
    height: 30vw;
  }
}

.checkbox {
  margin-bottom: 10px;
}

h2.about-banner-head {
  font-size: 6vw;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

h6.about-banner-head {
  font-size: 4vw;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);

  font-family: "Dancing Script";
}

.head-strip {
  background: rgb(51, 122, 183);
  color: white;
  font-size: 18px;
  padding: 0.5%;
  margin-bottom: 10px;
  padding-left: 2%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.form-container {
  box-sizing: border-box;
  margin: 15px;
  padding: 30px;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
}

.inner-container {
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
}

.errorMsg {
  font-size: 13px;
  color: red;
  margin-bottom: 14px;
}

.rdbtn {
  margin: 5px;
}

.rdbtnlabel {
  margin-right: 14px;
}

.yearcss {
  size: 300%;
}

/*Image Preview*/

.container-image {
  background-color: #ddd;
  float: right;
  height: 10px;
}

.img-holder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  min-height: auto;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.img-holder img {
  width: 10vw;
  height: 10vw;
  border-radius: 5%;
  border: 1px solid slategray;
  cursor: pointer;
}

.img-holder button {
  margin: 0.5em 0;
  width: 10vw;
  height: 10vw;
  background: #6c757d;
}

#imageinput[type="file"] {
  display: none;
  size: 5px;
}

.label {
  width: 100%;
}

.quali-sec {
  margin-bottom: 20px;
}

.quali-row {
  margin-top: 1%;
}

.image-upload {
  width: 230px;
  height: 30px;
  background-color: black;
  color: white;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.profile_img {
  height: 80%;
  width: 50%;
  float: right;
}

.form-heading {
  font-size: 36px;
}

.heading {
  /* position: relative; */
}

@media screen and (max-width: 768px) {
  .profile_img {
    height: 80%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .form-heading {
    padding: 0 auto;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 28px;
    margin-left: 5%;
    margin-top: 2%;
    margin-right: 2%;
  }

  .img-holder {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    min-height: auto;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
  }

  .img-holder img {
    width: 50%;
    height: auto;
    border-radius: 5%;
    border: 1px solid slategray;
  }

  .img-holder button {
    margin: 0.5em 0;
    width: 30vw;
    height: 30vw;
  }

  /* .form-container {
    box-sizing: border-box;
    margin: 15px;
    padding: 20px;
    padding-left: 0.5%;
    padding-right: 0.5%;
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border: 0px solid rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
  } */
}

.checkbox {
  margin-bottom: 10px;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-weight: bolder; */
  /* font-family: "Balsamiq Sans", cursive; */
  font-family: "Noto Sans TC", sans-serif;
  font-size: 20px;
}

.banner-img {
  height: 80vh;
  width: 100%;
}

.choose-container {
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
}

.choose-card-row {
  margin-top: 1%;
  padding-top: 1.5%;
}

.choose-card-icon {
  width: 100px;
  height: 100px;
}

.choose-card {
  color: black;
  cursor: pointer;
  border-radius: 0;
  border: 0;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s; /* Animation */
}

.choose-card:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
  -webkit-transform: -50% -50%;
          transform: -50% -50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.choose-card-text {
  font-size: 13px;
  color: #5d5a5a;
}

.key-container {
  background: url(/static/media/circuitBg.4ef47c0d.jpg);
  /* background-attachment: fixed; */
  background-size: cover;
  min-height: 300px;
  color: #ffffff;
}

.key-container > .row > .col-md-3 {
  padding-top: 8%;
  font-size: 20px;
}

.key-container > .row > .col-md-3 > h2 {
  font-weight: bolder;
  padding-left: 25%;
}

.key-container > .row > .col-md-3 > hr {
  background: #ffffff;
}

.key-container > .row > .col-md-3 > p {
  font-weight: bold;
  font-size: 14px;
}

.product-container {
  margin-top: 1%;
  margin-bottom: 1%;
  padding-top: 1%;
  min-height: 60vh;
  height: auto;
}

.product-text {
  min-height: 40vh;
  height: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.product-text::-webkit-scrollbar {
  display: none;
}

.product-row {
  margin-top: 1%;
  padding: 2%;
}

.product-list {
  border-radius: 0;
}

.product-list-item {
  padding: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 0;
  border: 0;
  margin-bottom: 15px;
  background: #2541b2;
  color: white;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  cursor: pointer;
}

.product-list-item:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.product-list-item-name {
  padding: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.list-group-item:first-child {
  border-radius: 0;
}

.list-group-item:last-child {
  border-radius: 0;
}

.services-container {
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
  /* background: #ecf5ff; */
  background: #d6d9e4;
}

.service-card {
  height: 200px;
  overflow: auto;
  border-radius: 10px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -ms-overflow-style: none;
}

.service-card::-webkit-scrollbar {
  display: none;
}

.service-card:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.service-col {
  padding: 2%;
}

.service-icon {
  margin-left: 20px;
  padding-top: 5rem;
}

.contact-container {
  margin-top: 1%;
  margin-bottom: 1%;
  padding-top: 1%;
}

.contact-row {
  padding-top: 1%;
  margin-top: 1%;
}

.contact-add {
  font-size: 14px;
}

.footer-container {
  padding-top: 1.5%;
  min-height: 30vh;
  /* background: #2541b2; */
  background: #dedfe2;
  color: #000000;
}

.font-size-16 {
  font-size: 16px;
}

.footer-content {
  font-size: 13px;
}

.copy-container {
  background: #223788;
  color: #ffffff;
  height: 50px;
}

.copy-text {
  padding: 1%;
}

.allProducts-section {
  margin-top: 1%;
  height: auto;
}

.allProducts-col {
  margin-bottom: 5px;
  padding: 20px;
  cursor: pointer;
}

.product-img-wrap {
  margin: 1%;
}

.product-img-text {
  color: #000;
  font-weight: bolder;
}

.product-img {
  opacity: 1;
}

.product-desc-container {
  background: #fff;
  color: #000;
  padding-top: 1%;
  padding-bottom: 1%;
}

.product-desc {
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 20px;
}

.product-desc > h2 {
  font-weight: bolder;
}

.product-desc-container > .row {
  margin: 0;
  padding: 0;
}

.product-desc-container > .container > .row > .col-md-6 {
  margin: 0;
  padding: 0;
}

.product-desc-container
  > .container
  > .row
  > .col-md-6
  > .product-content-col
  > h2 {
  font-weight: bolder;
}

.product-content-col {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  height: 400px;
  overflow: auto;
  -ms-overflow-style: none;
}

.product-content-col::-webkit-scrollbar {
  display: none;
}

.ppage-main-container > .jumbotron {
  margin-bottom: 0;
  padding-bottom: 0;
}

.product-variation-container {
  margin-top: 1%;
  padding: 20px;
}

.variation-col {
  margin-bottom: 20px;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.variation-col:hover {
  cursor: pointer;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.variation-text {
  background: #fff;
  color: #000;
  height: 60px;
  font-weight: bolder;
}

.banner-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.form-control {
  font-size: 13px;
  height: calc(1.25em + 0.75rem + 2px);
}

.btn-primary {
  border-radius: 0;
  background: #2541b2;
}

.modal-content {
  border-radius: 0;
}

.sm-display {
  display: none;
}

.card-title {
  font-weight: bolder;
}

.service-desc-container {
  padding-top: 1%;
  margin-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 1%;
  background: #eaebf0;
}

.service-desc-container > .para-div > .row {
  margin: 4%;
  padding: 2%;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .service-desc-container > .para-div > .row > .col-md-6 {
  margin: 0;
  padding: 0;
} */

.service-content-col > h2 {
  font-weight: bolder;
}

.service-content-col {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  height: 300px;
  overflow: auto;
  -ms-overflow-style: none;
  font-size: 13px;
}

.service-content-col::-webkit-scrollbar {
  display: none;
}

.service-product-variants-card-container {
  padding: 1%;
}

.service-product-variants-card {
  width: 18rem;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.service-product-variants-card:hover {
  width: 18rem;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  cursor: pointer;
}

.service-products-variants-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
}

.service-products-variants-container::-webkit-scrollbar {
  display: none;
}

.service-product-col {
  background: white;
  color: #000;
  padding-right: 0;
  padding-left: 0;
}

.service-product-col-items {
  margin: 2%;
  padding: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 500px;
  position: relative;
}

.service-product-col-content {
  margin: 3%;
}

.service-product-col-content-button {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10%;
}

.md-text {
  font-size: 18px;
}

.modal-body {
  min-height: 400px;
  height: auto;
  padding: 0;
}

.modal-body > .row > .col,
.col-sm-6 {
  min-height: 400px;
  padding: 0;
  margin: 0;
}

.modal-body > .row > .col-sm-6,
.col > .modal--image {
  height: 100%;
  width: 100%;
  margin-left: 3%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-body > .row > .col-sm-6,
.col > .modal--content {
  padding: 10%;
  position: relative;
  height: 100%;
  text-align: justify;
}

.modal-body > .row > .col-sm-6,
.col > .modal--content > hr {
  border-top: 2px solid rgba(221, 154, 154, 0.1);
  background: red;
}

.follow-us-icon {
  font-size: larger;
  margin: 1%;
  cursor: pointer;
}

.modal-body > .row > .col-sm-6,
.col > .modal--content > .modal--button {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10%;
}

.dropdown-item {
  font-weight: normal;
  font-weight: initial;
  font-size: 13px;
}

.tech-used-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #dedfe2;
}

.techLogo {
  max-width: 75px;
  max-height: 75px;
  margin: 1%;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.techLogo:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

.carousel-indicators li {
  display: none;
}

.carousel-caption {
  left: 0;
  bottom: 0;
  top: 30%;
  right: -65%;
  font-family: "Roboto", sans-serif;
  font-size: 72px;
}

.carousel-caption > p {
  display: block;
}

.carousel-caption > p > span {
  display: block;
}

.secondCaptionWord {
  font-weight: 700;
  margin-top: -2%;
  margin-left: 3.5%;
  color: #ee0909e2;
}

.firstCaptionWord {
}

@media (max-width: 991.98px) {
  .key-container > .row > .col-md-3 > h2 {
    padding-left: 2%;
  }

  .key-container > .row > .col-md-3 {
    padding: 1%;
    margin: 1%;
  }

  .nm-display {
    display: none;
  }

  .sm-display {
    display: inline-block;
  }

  .service-content-col {
    margin-top: 10px;
  }
}

.main-container {
  margin-top: 2%;
  margin-bottom: 2%;
}

.search-box {
  width: 100%;
}

.searchbox-container {
  margin-top: -1.5%;
}

.positions-text {
}

.positions {
  font-size: 20px;
  color: #9ad252;
  text-decoration: underline;
}

.positions-text:hover {
  cursor: pointer;
  color: rgb(106, 106, 235);
}

.bottom-margin {
  margin-bottom: 2%;
}

.quali-row > .col-md-1,
.col-md-2 {
  padding-right: 5px;
  padding-left: 5px;
}

.departments-container {
  background-color: #d6d9e4;
  padding: 2%;
}

.departments-container-one {
  background-color: #ffffff;
  padding: 2%;
}

.departments-cards {
  margin-bottom: 10px;
  cursor: pointer;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  height: 400px;
}

.departments-cards:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.skew-container {
  -webkit-transform: skewy(-11deg);
          transform: skewy(-11deg);
}

.blocks {
  display: -webkit-flex;
  display: flex;
  margin-top: 0;
  margin-bottom: 1%;
}

.block {
  min-height: 350px;
  width: 50%;
  width: calc(50% + 2rem);
}

.block--left {
  padding-right: 1%;
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 3rem) 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, calc(100% - 3rem) 100%, 0% 100%);
}

.block--right {
  background: url(/static/media/office.aae17375.jpg);
  margin-left: -3rem;
  -webkit-clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0% 100%);
}

.p-blocks {
  display: -webkit-flex;
  display: flex;
  margin-top: 0;
  margin-bottom: 1%;
}

.p-block {
}

.getInTouchBg {
  background: url(/static/media/process-getintouch.a8c5a362.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.callBg {
  background: url(/static/media/process_call.5c792eba.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.assignmentBg {
  background: url(/static/media/process_assignment.86134675.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.reviewBg {
  background: url(/static/media/process_review.75a711ba.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.techInterviewBg {
  background: url(/static/media/process_tech.e55ecfd2.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.raiserBg {
  background: url(/static/media/process_rasier.13dfe1fc.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.p-block--left {
  width: 70%;
  padding: 2%;
  padding-right: 5%;
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 3rem) 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, calc(100% - 3rem) 100%, 0% 100%);
}

.p-block--right {
  width: 40%;

  margin-left: -3rem;
  -webkit-clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0% 100%);
}

.l-blocks {
  display: -webkit-flex;
  display: flex;
  margin-top: 0;
  margin-bottom: 1%;
  background: #f7f7f7;
}

.l-block {
}

.l-block--left {
  width: 40%;
  /* background: url(../../images/office.jpg); */
  margin-right: -3%;
  margin-left: -3%;
  -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
}

.l-block--right {
  width: 70%;
  padding: 2%;
  padding-left: 5%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 6% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 6% 100%);
}

.usp-content {
  padding-top: 15%;
  padding-right: 2%;
  font-size: 20px;
  margin-left: 1%;
  color: #7d7d7d;
  text-align: center;
}

.process-container {
  font-size: 14px;
  margin-top: 2%;
}

.process-container-text {
  color: #7d7d7d;
}

.process-container > .container-fluid > ol > li {
  margin-top: 2%;
}

.career-modal-body {
  min-height: auto;
  height: auto;
  padding: 2%;
}

/* .advantages-container {
} */

.advantages-card {
  color: #000;
  margin-bottom: 1%;
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  text-align: left;
}

.advantages-card-body {
  padding: 0;
  padding-top: 3%;
}

.advantages-col {
  margin-top: 1%;
  margin-bottom: 5%;
}

.quotes-container {
  margin-top: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: #f3f1f1;
}

.main-quote-line-1 {
  font-size: 48px;
  color: #7d7d7d;
}

.main-quote-line-2 {
  font-size: 48px;
  color: #7d7d7d;
  margin-top: -0.5%;
}

.main-quote-line-3 {
  font-size: 48px;
  color: #f5a800;
  margin-top: -0.5%;
}

.second-quote-line-1 {
  margin-top: 4%;
  font-size: 32px;
  color: #e27d00;
  margin-bottom: 4%;
}

.dep-images {
  padding: 5%;
  height: 150px;
}

.process-header {
  font-size: 18px;
  color: #7d7d7d;
  font-weight: bold;
}

label {
  margin-bottom: 0.2rem;
}

.form-control::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c7c3c3;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c7c3c3;
  opacity: 1; /* Firefox */
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c7c3c3;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c7c3c3;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c7c3c3;
}

@media screen and (max-width: 768px) {
  .process-container {
    display: none;
  }

  .p-block--left {
    width: 100%;
    padding: 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .p-block--right {
    display: none;
  }

  .l-block--left {
    display: none;
  }

  .l-block--right {
    width: 100%;
    padding: 0;

    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .l-blocks {
    background: #ffffff;
  }

  .block--right {
    display: none;
  }

  .block--left {
    width: 100%;
  }
}

