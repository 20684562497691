@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-weight: bolder; */
  /* font-family: "Balsamiq Sans", cursive; */
  font-family: "Noto Sans TC", sans-serif;
  font-size: 20px;
}

.banner-img {
  height: 80vh;
  width: 100%;
}

.choose-container {
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
}

.choose-card-row {
  margin-top: 1%;
  padding-top: 1.5%;
}

.choose-card-icon {
  width: 100px;
  height: 100px;
}

.choose-card {
  color: black;
  cursor: pointer;
  border-radius: 0;
  border: 0;
  transition: transform 0.4s; /* Animation */
}

.choose-card:hover {
  transform: scale(1.07);
  transform: -50% -50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.choose-card-text {
  font-size: 13px;
  color: #5d5a5a;
}

.key-container {
  background: url("images/circuitBg.jpg");
  /* background-attachment: fixed; */
  background-size: cover;
  min-height: 300px;
  color: #ffffff;
}

.key-container > .row > .col-md-3 {
  padding-top: 8%;
  font-size: 20px;
}

.key-container > .row > .col-md-3 > h2 {
  font-weight: bolder;
  padding-left: 25%;
}

.key-container > .row > .col-md-3 > hr {
  background: #ffffff;
}

.key-container > .row > .col-md-3 > p {
  font-weight: bold;
  font-size: 14px;
}

.product-container {
  margin-top: 1%;
  margin-bottom: 1%;
  padding-top: 1%;
  min-height: 60vh;
  height: auto;
}

.product-text {
  min-height: 40vh;
  height: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.product-text::-webkit-scrollbar {
  display: none;
}

.product-row {
  margin-top: 1%;
  padding: 2%;
}

.product-list {
  border-radius: 0;
}

.product-list-item {
  padding: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 0;
  border: 0;
  margin-bottom: 15px;
  background: #2541b2;
  color: white;
  transition: transform 0.1s;
  cursor: pointer;
}

.product-list-item:hover {
  transform: scale(1.1);
}

.product-list-item-name {
  padding: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.list-group-item:first-child {
  border-radius: 0;
}

.list-group-item:last-child {
  border-radius: 0;
}

.services-container {
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
  /* background: #ecf5ff; */
  background: #d6d9e4;
}

.service-card {
  height: 200px;
  overflow: auto;
  border-radius: 10px;
  transition: transform 0.2s;
  -ms-overflow-style: none;
}

.service-card::-webkit-scrollbar {
  display: none;
}

.service-card:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.service-col {
  padding: 2%;
}

.service-icon {
  margin-left: 20px;
  padding-top: 5rem;
}

.contact-container {
  margin-top: 1%;
  margin-bottom: 1%;
  padding-top: 1%;
}

.contact-row {
  padding-top: 1%;
  margin-top: 1%;
}

.contact-add {
  font-size: 14px;
}

.footer-container {
  padding-top: 1.5%;
  min-height: 30vh;
  /* background: #2541b2; */
  background: #dedfe2;
  color: #000000;
}

.font-size-16 {
  font-size: 16px;
}

.footer-content {
  font-size: 13px;
}

.copy-container {
  background: #223788;
  color: #ffffff;
  height: 50px;
}

.copy-text {
  padding: 1%;
}

.allProducts-section {
  margin-top: 1%;
  height: auto;
}

.allProducts-col {
  margin-bottom: 5px;
  padding: 20px;
  cursor: pointer;
}

.product-img-wrap {
  margin: 1%;
}

.product-img-text {
  color: #000;
  font-weight: bolder;
}

.product-img {
  opacity: 1;
}

.product-desc-container {
  background: #fff;
  color: #000;
  padding-top: 1%;
  padding-bottom: 1%;
}

.product-desc {
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 20px;
}

.product-desc > h2 {
  font-weight: bolder;
}

.product-desc-container > .row {
  margin: 0;
  padding: 0;
}

.product-desc-container > .container > .row > .col-md-6 {
  margin: 0;
  padding: 0;
}

.product-desc-container
  > .container
  > .row
  > .col-md-6
  > .product-content-col
  > h2 {
  font-weight: bolder;
}

.product-content-col {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  height: 400px;
  overflow: auto;
  -ms-overflow-style: none;
}

.product-content-col::-webkit-scrollbar {
  display: none;
}

.ppage-main-container > .jumbotron {
  margin-bottom: 0;
  padding-bottom: 0;
}

.product-variation-container {
  margin-top: 1%;
  padding: 20px;
}

.variation-col {
  margin-bottom: 20px;
  transition: transform 0.4s;
}

.variation-col:hover {
  cursor: pointer;
  transform: scale(1.02);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.variation-text {
  background: #fff;
  color: #000;
  height: 60px;
  font-weight: bolder;
}

.banner-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-control {
  font-size: 13px;
  height: calc(1.25em + 0.75rem + 2px);
}

.btn-primary {
  border-radius: 0;
  background: #2541b2;
}

.modal-content {
  border-radius: 0;
}

.sm-display {
  display: none;
}

.card-title {
  font-weight: bolder;
}

.service-desc-container {
  padding-top: 1%;
  margin-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 1%;
  background: #eaebf0;
}

.service-desc-container > .para-div > .row {
  margin: 4%;
  padding: 2%;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .service-desc-container > .para-div > .row > .col-md-6 {
  margin: 0;
  padding: 0;
} */

.service-content-col > h2 {
  font-weight: bolder;
}

.service-content-col {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  height: 300px;
  overflow: auto;
  -ms-overflow-style: none;
  font-size: 13px;
}

.service-content-col::-webkit-scrollbar {
  display: none;
}

.service-product-variants-card-container {
  padding: 1%;
}

.service-product-variants-card {
  width: 18rem;
  transition: transform 0.4s;
}

.service-product-variants-card:hover {
  width: 18rem;
  transform: scale(1.05);
  cursor: pointer;
}

.service-products-variants-container {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
}

.service-products-variants-container::-webkit-scrollbar {
  display: none;
}

.service-product-col {
  background: white;
  color: #000;
  padding-right: 0;
  padding-left: 0;
}

.service-product-col-items {
  margin: 2%;
  padding: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 500px;
  position: relative;
}

.service-product-col-content {
  margin: 3%;
}

.service-product-col-content-button {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10%;
}

.md-text {
  font-size: 18px;
}

.modal-body {
  min-height: 400px;
  height: auto;
  padding: 0;
}

.modal-body > .row > .col,
.col-sm-6 {
  min-height: 400px;
  padding: 0;
  margin: 0;
}

.modal-body > .row > .col-sm-6,
.col > .modal--image {
  height: 100%;
  width: 100%;
  margin-left: 3%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-body > .row > .col-sm-6,
.col > .modal--content {
  padding: 10%;
  position: relative;
  height: 100%;
  text-align: justify;
}

.modal-body > .row > .col-sm-6,
.col > .modal--content > hr {
  border-top: 2px solid rgba(221, 154, 154, 0.1);
  background: red;
}

.follow-us-icon {
  font-size: larger;
  margin: 1%;
  cursor: pointer;
}

.modal-body > .row > .col-sm-6,
.col > .modal--content > .modal--button {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10%;
}

.dropdown-item {
  font-weight: initial;
  font-size: 13px;
}

.tech-used-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #dedfe2;
}

.techLogo {
  max-width: 75px;
  max-height: 75px;
  margin: 1%;
  transition: transform 0.2s;
}

.techLogo:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

.carousel-indicators li {
  display: none;
}

.carousel-caption {
  left: 0;
  bottom: 0;
  top: 30%;
  right: -65%;
  font-family: "Roboto", sans-serif;
  font-size: 72px;
}

.carousel-caption > p {
  display: block;
}

.carousel-caption > p > span {
  display: block;
}

.secondCaptionWord {
  font-weight: 700;
  margin-top: -2%;
  margin-left: 3.5%;
  color: #ee0909e2;
}

.firstCaptionWord {
}

@media (max-width: 991.98px) {
  .key-container > .row > .col-md-3 > h2 {
    padding-left: 2%;
  }

  .key-container > .row > .col-md-3 {
    padding: 1%;
    margin: 1%;
  }

  .nm-display {
    display: none;
  }

  .sm-display {
    display: inline-block;
  }

  .service-content-col {
    margin-top: 10px;
  }
}
